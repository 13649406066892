.drawerModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);;
  display: flex;
}

.drawerBody {
  width: 40em;
  background-color: cadetblue;
  color: white;
}

.drawerContent {
  padding: 1.5em 0em;
}

.drawerTop {
  max-height: 5em;
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-left: 1em;
}

.drawerCloseButton {
  height: 100%;

  > button {
    height: 100%;
    background-color: cadetblue;
    border: 0;
    outline: 0;
    cursor: pointer;

    > svg {
      width: 6em;
      fill: white;
    }
  }
}

.drawerContentLink {
  display: flex;
  flex-flow: column;
  height: 3em;

  > button {
    height: inherit;
    text-align: start;
    font-size: 2em;
    border: 0;
    outline: 0;
    background-color: inherit;
    color: inherit;
    cursor: pointer;
    padding-left: 1em;
  }
}