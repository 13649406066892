import React from "react";
import { GameType } from "../../common/enums/GameType";
import Game from "../../common/interfaces/Game";
import * as styles from "./game.module.scss";

const createGameSetupDropdownMenu = (
  optionsCollection: JSX.Element[],
  forId: string,
  selectName: string,
  labelMessage: string,
  defaultMessage: string,
  onChangeHandler: (value: string) => void,
): JSX.Element => {
  const dropdownMenu: JSX.Element = (
    <div className={styles.setupDropdownContainer}>
      <label htmlFor={forId} className={styles.setupLabel}>{labelMessage}</label>
      <select name={selectName} id={forId} onChange={(e) => onChangeHandler(e.target.value)} required className={styles.setupSelect}>
        <option value="">{defaultMessage}</option>
        {optionsCollection}
      </select>
    </div>
  );

  return dropdownMenu;
};

const setupGameMode = (gameType: GameType): Game|null => {
  let game: Game|null;

  switch (gameType) {
    case GameType.BEST_OF_X:
      game = {
        displayName: gameType.toString(),
        maxNumberOfPlayers: 2,
      }
      break;
    default:
      game = null;
  }

  return game;
}

export { createGameSetupDropdownMenu, setupGameMode } ;
