import React, { useState } from "react";
import Drawer from "../../components/drawer/drawer";
import DrawerLink from "../../common/interfaces/DrawerLink";
import Menu from "jsx:../../common/svg/menu.svg";
import { GameType } from "../../common/enums/GameType";
import { createGameSetupDropdownMenu, setupGameMode } from "./utils";
import Game from "../../common/interfaces/Game"
import Scoreboard from "../scoreboard/scoreboard";
import * as styles from "./game.module.scss";

const Game = (): JSX.Element => {  
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [gameType, setGameType] = useState<GameType>(GameType.NONE);
  const [gameInformation, setGameInformation] = useState<Game|null>(null);
  const [playerCount, setPlayerCount] = useState<number>(0);

  const newGame = (): void => {
    setGameType(GameType.NONE);
    setPlayerCount(0);
    setGameInformation(null);
    setShowDrawer(false);
  };

  const drawerLinks: DrawerLink[] = [
    {
      title: "New Game",
      onClickHandler: newGame,
    },
  ];

  const gameTypeChangeHandler = (gameType: string): void => {
    if (gameType && Object.values(GameType).indexOf(gameType as GameType) > 0) {
      setGameType(gameType as GameType);
      setGameInformation(setupGameMode(gameType as GameType));
    }
  };

  const playerNumberChangeHandler = (numberOfPlayers: string): void => {
    if (numberOfPlayers && parseInt(numberOfPlayers) > 0) {
      setPlayerCount(parseInt(numberOfPlayers));
    }
  };

  const createGameTypeDropdown = (): JSX.Element => {
    const gameTypes: [string, GameType][] = Object.entries(GameType).filter(filter => filter[1] !== GameType.NONE);
    const gameTypeOptions: JSX.Element[] = gameTypes.map((type: [string, GameType]) => {
      return (
        <option value={type[1]} key={type[1]}>{type[1]}</option>
      )
    });

    const gameDropdown: JSX.Element = createGameSetupDropdownMenu(
      gameTypeOptions,
      "game-select",
      "games",
      "Choose a game type:",
      "Select a game type",
      gameTypeChangeHandler,
    );

    return gameDropdown;
  }

  const createPlayerCountDropdonw = (numberOfPlayers: number): JSX.Element => {
    const options: JSX.Element[] = Array.from({ length: numberOfPlayers}, (_, x) => x+1).map((x: number) => {
      return(
        <option value={x} key={x}>{x}</option>
      )
    });

    const playerCountDropdown: JSX.Element = createGameSetupDropdownMenu(
      options,
      "player-count",
      "players",
      "Choose number of players:",
      "Select number of players",
      playerNumberChangeHandler,
    );

    return playerCountDropdown;
  };
  
  return (
    <>
      <div className={styles.appBody}>
        <div className={styles.appHeaderBar}>
          <div className={styles.leftMenuButton}>
            <button onClick={() => setShowDrawer(true)}>
              <Menu />
            </button>
          </div>
          <div className={styles.title}>
            <p>Axcellent Times</p>
          </div>
        </div>
        <div className={styles.appContent}>
          {gameType === GameType.NONE && (
            createGameTypeDropdown()
          )}
          {gameInformation && playerCount < 1 && (
            createPlayerCountDropdonw(gameInformation.maxNumberOfPlayers)
          )}
          {gameInformation && playerCount > 0 && (
            <Scoreboard
              gameTitle={gameInformation.displayName}
              gameType={gameType}
              numberOfPlayers={playerCount}
            />
          )}
        </div>
      </div>
      <Drawer
        isOpen={showDrawer}
        closeDrawer={setShowDrawer}
        drawerLinks={drawerLinks}
      />
    </>
  );
}

export default Game;