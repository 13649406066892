
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { GameType } from "../../common/enums/GameType";
import debounce from "../../common/utils/debounce";
import * as styles from "./scoreboard.module.scss";

const VALID_SCORES: number[] = [0,1,2,3,4,6,8];
const VALID_SCORE_PATTERN: string = "^8|(^[0|1|2|3|4|6])";

const Scoreboard = ({
  gameTitle,
  numberOfPlayers,
}: ScoreboardProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [gameSetting] = useState<number>(10);
  const [playerScorecards, setPlayerScorecards] = useState<Scorecard[]>([])

  const createScoreCards = (playerCount: number, gameOptions: number): void => {
    const scores: Scorecard[] = Array.from({ length: playerCount}, (_, x) => x+1).map((x: number) => {
      return(
        {
          playerName: `Player ${x}`,
          playerGameScores: Array.from({ length: gameOptions}),
          playerTotal: 0,
        }
      )
    });
    setPlayerScorecards(scores);
  }

  const nameChangeHandler = (playerIndex: number, newName: string): void => {
    if (newName) {
      const newScorecards: Scorecard[] = playerScorecards.map((scorecard: Scorecard, index: number) => {
        if (playerIndex === index) {
          return {...scorecard, playerName: newName};
        } else {
          return scorecard;
        }
      });
      setPlayerScorecards(newScorecards);
    }
  };

  const debouncedNameChangeHandler = useCallback(
    debounce(nameChangeHandler, 1000)
  , [nameChangeHandler]);

  const updateTotal = (scores: (number | undefined)[]): number => {
    let updatedTotal: number = 0;
    scores.forEach((score: number | undefined) => {
      if (typeof score === "number") {
        updatedTotal += score;
      }
    });

    return updatedTotal;
  }

  const scoreChangeHandler = (playerIndex: number, scoreIndex: number, scoreValue: string, event: ChangeEvent<HTMLInputElement>): void => {
    const targetElement = inputRefs.current.find((el) => el?.name === event.target.name);
    if (targetElement && !VALID_SCORES.includes(parseInt(scoreValue)) && !targetElement.classList.contains(styles.badScore)) {
      targetElement.classList.add(styles.badScore);
    }
    if (targetElement && (VALID_SCORES.includes(parseInt(scoreValue)) || !scoreValue)) {
      if (targetElement.classList.contains(styles.badScore) && scoreValue) {
        targetElement.classList.remove(styles.badScore);
      }
      const newScorecards: Scorecard[] = playerScorecards.map((scorecard: Scorecard, index: number) => {
        if (playerIndex === index) {
          const newPlayerGameScores: (number | undefined)[] = scorecard.playerGameScores;
          newPlayerGameScores[scoreIndex] = scoreValue ? parseInt(scoreValue) : undefined;
          const newTotal: number = updateTotal(newPlayerGameScores);
          return {...scorecard, playerGameScores: newPlayerGameScores, playerTotal: newTotal };
        } else {
          return scorecard;
        }
      });
      setPlayerScorecards(newScorecards);
    }
  }

  const debouncedScoreChangeHandler = useCallback(
    debounce(scoreChangeHandler, 100)
  , [scoreChangeHandler]);

  const createScoreGrid = (): JSX.Element => {
    const playerScoreColumn: JSX.Element[] = playerScorecards.map((scorecard: Scorecard, playerIndex: number) => {
      return (
        <div 
          key={`${scorecard.playerName}-${playerIndex}`} 
          className={playerIndex !== playerScorecards.length -1 ? styles.padRight : ""}
        >
          <div className={styles.playerNamePadding}>
            <input
              type="text"
              placeholder={scorecard.playerName}
              defaultValue={scorecard.playerName}
              key={`${scorecard.playerName}-${playerIndex}`}
              className={styles.playerName}
              onChange={(e) => debouncedNameChangeHandler(playerIndex, e.target.value)}
            />
          </div>
          <div className={styles.scoreList}>
            {Array.from(Array(gameSetting), (_, x) => x+1).map((scoreIndex: number) => {
              return(
                <input
                  ref={(el) => (inputRefs.current[gameSetting * playerIndex + (scoreIndex - 1)] = el)}
                  name={`p${playerIndex}-${scoreIndex-1}`}
                  type="text"
                  defaultValue={scorecard.playerGameScores[scoreIndex - 1]}
                  pattern={VALID_SCORE_PATTERN}
                  key={`${scorecard.playerName}-${playerIndex}-${scoreIndex - 1}`}
                  className={styles.scoreValue}
                  onChange={(e) => debouncedScoreChangeHandler(playerIndex, scoreIndex -1 , e.target.value, e)}
                />
              )
            })}
          </div>
          <div className={styles.playerTotalPadding}>
            <input
              type="text"
              value={scorecard.playerTotal}
              key={scorecard.playerName}
              className={styles.playerTotal}
              readOnly
            />
          </div>
        </div>
      )
    });

    return (
      <div className={styles.scoreGrid}>
        {playerScoreColumn}
      </div>
    )
  }

  useEffect(() => {
    if (gameSetting > 0) {
      createScoreCards(numberOfPlayers, gameSetting);
    }
  }, [gameSetting]);

  return (
    <div>
      <h1 className={styles.gameName}>{gameTitle}</h1>
      {playerScorecards.length > 0 && (
        <form ref={formRef}>
          {createScoreGrid()}
        </form>
      )}
    </div>
  )
}

interface ScoreboardProps {
  gameTitle: string;
  gameType: GameType;
  numberOfPlayers: number;
}

interface Scorecard {
  playerName: string;
  playerGameScores: Array<number | undefined>;
  playerTotal: number;
}

export default Scoreboard;