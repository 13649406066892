.appBody {
  height: 100vh;
  background-color: black;
  color: wheat;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.appHeaderBar {
  height: 100%;
  max-height: 5em;
  min-height: 2em;
  display: flex;
  background-color: cadetblue;
  align-items: center;
  justify-content: center;
  position: relative;
}

.title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 2em;
    cursor: default;
  }
}

.leftMenuButton {
  position:absolute;
  left:0;
  top:0;
  height: 100%;

  > button {
    height: 100%;
    background-color: cadetblue;
    border: 0;
    outline: 0;
    cursor: pointer;

    > svg {
      width: 6em;
      fill: white;
    }
  }
}

.appContent {
  flex: 1 1 auto;
  min-height: 0px;
  overflow-y: auto;
  display: flex;
  width: 100%;
  justify-content: center;
}

.setupDropdownContainer {
  display: flex;
  flex-direction: column;
}

.setupLabel {
  font-size: 2em;
  padding-bottom: 1em;
}

.setupSelect {
  font-size: 1.5em;
}