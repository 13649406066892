import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DrawerLink from "../../common/interfaces/DrawerLink";
import Close from "jsx:../../common/svg/close.svg"
import styles from "./drawer.module.scss";

const Drawer = ({
  isOpen,
  closeDrawer,
  drawerLinks,
 }: DrawerPropsInterface): JSX.Element | null => {
  const [show, setShow] = useState<boolean>(false);

  const closeOnEscapeKeyPress = (event: KeyboardEvent): void => {
    if(event.isTrusted && event.code === 'Escape') {
      setShow(!show);
      closeDrawer(!show)
    }
  };

  useEffect((): void => {
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyPress);

    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyPress);
    };
  }, []);

  return show ? 
    ReactDOM.createPortal(
      <div className={styles.drawerModal} onClick={() => closeDrawer(!show)}>
        <div className={styles.drawerBody} onClick={(e) => e.stopPropagation()}>
          <div className={styles.drawerTop}>
            <div className={styles.drawerCloseButton}>
              <button onClick={() => closeDrawer(!show)}>
                <Close />
              </button>
            </div>
          </div>
          <div className={styles.drawerContent}>
            {drawerLinks && drawerLinks!.map((link: DrawerLink, linkIndex: number) => {
              return (
                <div className={styles.drawerContentLink} key={`${link.title}-${linkIndex}`}>
                  <button onClick={() => link.onClickHandler()}>
                    {link.title}
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      </div>,
      document.getElementById('app')!
    )
  : null;
};

Drawer.defaultProps = {
  drawerLinks: null,
};

interface DrawerPropsInterface {
  isOpen: boolean;
  closeDrawer: (value: boolean) => void;
  drawerLinks?: DrawerLink[];
}

export default Drawer;