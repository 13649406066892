.gameName {
  text-align: center;
}

.scoreGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.scoreList {
  display: flex;
  flex-direction: column;
}

.scoreValue {
  background-color: cadetblue;
  color: white;
  font-size: 1.5em;

  &:focus {
    background-color: darkgoldenrod;
  }
}

.padRight {
  padding-right: 1em;;
}

.playerName {
  font-size: 1.5em;
  background-color: cadetblue;
  text-align: center;
  color: wheat;
}

.playerNamePadding {
  padding-bottom: 1em;
}

.playerTotal {
  font-size: 1.5em;
  background-color: cadetblue;
  text-align: center;
  color: wheat;
}

.playerTotalPadding {
  padding-top: 1em;
}

.badScore {
  background-color: maroon;
  
  &:focus {
    background-color: maroon;
  }
}